<script>
import * as TWEEN from "@tweenjs/tween.js";

export default {
  name: "CourseProgress",

  props: {
    percent: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      number: 0,
    };
  },

  watch: {
    percent(newValue) {
      this.tween(0, newValue);
    },
  },

  computed: {
    maxWidth() {
      return `max-width: ${this.percent}%`;
    },
  },

  methods: {
    tween: function (startValue, endValue) {
      let vm = this;

      function animate() {
        if (TWEEN.update()) {
          requestAnimationFrame(animate);
        }
      }

      new TWEEN.Tween({ number: startValue })
        .to({ number: endValue }, 1500)
        .onUpdate(function (object) {
          vm.number = new Intl.NumberFormat("ru-RU", {
            style: "decimal",
          }).format(object.number.toFixed(0));
        })
        .start();

      animate();
    },
  },

  mounted() {
    this.tween(0, this.percent);
  },
};
</script>

<template>
  <div class="course-progress">
    <p>
      <span>Прогресс прохождения:</span>
      <output>{{ number }}%</output>
    </p>

    <div class="progress-bar">
      <div class="progress-bar__progress" :style="maxWidth" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.course-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
  padding: 12px 16px 16px 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  background: #fff0c7;

  > p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include text-2-bold;
  }
}

.progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 8px;
  background: $light-primary;

  &__progress {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: $dark-primary;
    animation: animationProgress 2s ease-in-out;
  }
}

@keyframes animationProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
