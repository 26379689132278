<script>
export default {
  name: "IconProgress",

  props: {
    id: {
      type: Number,
      required: true,
    },

    progress: {
      type: Number,
      default: 0,
    },
  },

  mounted() {
    this.$nextTick(() => {
      document
        .getElementById(`${this.id}`)
        .setAttribute("data-progress", `${0}`);

      let callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              document
                .getElementById(`${this.id}`)
                .setAttribute("data-progress", `${this.progress}`);
            }, 500);
          }
        });
      };

      let options = {
        root: document.getElementById("lesson-item__progress"),
        rootMargin: "0px",
        threshold: 1,
      };

      let observer = new IntersectionObserver(callback, options);

      let target = document.getElementById(`${this.id}`);
      observer.observe(target);
    });
  },
};
</script>

<template>
  <div :id="id" class="ko-progress-circle" data-progress="0">
    <div class="ko-circle">
      <div class="full ko-progress-circle__slice">
        <div class="ko-progress-circle__fill" />
      </div>
      <div class="ko-progress-circle__slice">
        <div class="ko-progress-circle__fill" />
        <div class="ko-progress-circle__fill ko-progress-circle__bar" />
      </div>
    </div>

    <span v-if="progress === 100" class="icon-circle-done" />
  </div>
</template>

<style scoped lang="scss">
$circle-size: 16px;

.ko-progress-circle {
  width: 16px;
  height: $circle-size;
  position: relative;
  background-color: $light-third;
  border-radius: 50%;

  .ko-progress-circle__slice,
  .ko-progress-circle__fill {
    width: 16px;
    height: 16px;
    position: absolute;
    -webkit-backface-visibility: hidden;
    transition: transform 0.7s ease-out;
    border-radius: 50%;
  }
  .ko-progress-circle__slice {
    clip: rect(0px, $circle-size, $circle-size, 8);
    .ko-progress-circle__fill {
      clip: rect(0px, 8px, $circle-size, 0px);
      background-color: $dark-primary;
    }
  }

  $i: 0;
  $increment: 1.8deg;
  @while $i <= 100 {
    &[data-progress="#{$i}"] {
      .ko-progress-circle__slice.full,
      .ko-progress-circle__fill {
        transform: rotate($increment * $i);
      }
      .ko-progress-circle__fill.ko-progress-circle__bar {
        transform: rotate($increment * $i * 2);
      }
      $i: $i + 1;

      @if $i < 100 {
        .icon-circle-done {
          opacity: 0;
        }
      } @else {
        .icon-circle-done {
          opacity: 1;
        }
      }
    }
  }
}

.ko-circle {
  z-index: 1;
}

.icon-circle-done {
  min-width: 16px;
  height: 16px;
  background: $light-primary;
  z-index: 2;
  transition: opacity 3s;
}
</style>
