<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },

    arrowPosition: {
      type: String,
      default: "left",
    },

    buttonStyle: {
      type: String,
      default: "transparent",
    },

    icon: {
      type: String,
      default: "arrow-medium",
    },
  },
};
</script>

<template>
  <button
    class="button-redirect"
    :class="[
      { 'button-redirect_reverse': arrowPosition === 'right' },
      `button-redirect_${buttonStyle}`,
    ]"
    @click="$emit('click')"
  >
    <span class="button-redirect__icon" :class="`icon-${icon}`" />
    <span class="button-redirect__text">{{ text }}</span>
  </button>
</template>

<style lang="scss" scoped>
.button-redirect {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  @include body-1;
  color: $dark-third;
  padding: 0;
  text-align: start;
  gap: $space-s;

  &:hover,
  &:focus {
    color: $dark-primary;

    .button-redirect {
      &__text {
        translate: 4px;
      }
    }

    .button-redirect__icon {
      background-color: $dark-primary;
    }
  }

  &:active {
    .button-redirect {
      &__text {
        translate: 4px;
      }
    }
  }

  &_background {
    padding: 10px $space-l;
    background-color: $light-third;
    border-radius: $space-s;
    justify-content: flex-end;
    width: 100%;
    @include text-2;
  }

  &__icon {
    min-width: 16px;
    width: 16px;
    height: 16px;
    background: $dark-third;
    transition: 0.08s linear;
  }

  > .icon-arrow-medium {
    transform: rotate(180deg);
  }

  &__text {
    transition: 0.08s linear;
  }

  &_reverse {
    flex-direction: row-reverse;

    .button-redirect {
      &__icon {
        transform: rotate(0deg);
      }
    }

    &:hover,
    &:focus {
      .button-redirect {
        &__text {
          translate: 0;
        }

        &__icon {
          translate: 4px;
        }
      }
    }

    &:active {
      .button-redirect {
        &__text {
          translate: 0;
        }

        &__icon {
          translate: 4px;
        }
      }
    }
  }

  &_bold {
    @include text-2-bold;
    color: $dark-primary;

    .button-redirect__icon {
      mask: url("../../assets/icons/arrow_outline.svg") 0 / cover no-repeat;
      background: $dark-primary !important;
      transform: rotate(0);
      min-width: 16px;
    }
  }
}
</style>
