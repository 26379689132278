<script>
import { mapActions, mapState } from "vuex";
import { defineComponent } from "vue";
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import LessonItem from "@/components/coorser/LessonItem.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import CourseProgress from "@/components/coorser/CourseProgress.vue";

export default defineComponent({
  components: {
    CourseProgress,
    MainButton,
    LessonItem,
    MainLoader,
    ButtonRedirect,
  },

  computed: {
    ...mapState({
      course: (state) => state.coorser.course,
      user: (state) => state.auth.user,
    }),

    banner() {
      return (
        this.course.media?.cover?.url?.full_size ??
        require("../assets/images/no_photo.png")
      );
    },

    authorPhoto() {
      return (
        this.course.partner?.user?.media[0]?.url?.full_size ??
        require("../assets/images/no_avatar.png")
      );
    },

    authorName() {
      return `${this.course.partner?.user?.first_name ?? ""} ${
        this.course.partner?.user?.last_name ?? ""
      }`;
    },

    percent() {
      if (!this.course.stats) {
        return 0;
      }

      return Math.trunc(
        (this.course.stats.completed_units * 100) /
          (this.course.stats.total_units || 1)
      );
    },
  },

  methods: {
    ...mapActions({
      loadCourse: "coorser/loadCourse",
    }),

    toCoorser() {
      window.open(
        `${process.env.VUE_APP_COORSEER_URL}panel/courses/${this.course.id}?phone=${this.user.phone}`
      );
    },
  },

  mounted() {
    if (!this.$route.query.id) {
      this.$router.push({ name: "Study" });
    }

    this.loadCourse(this.$route.query.id);
  },
});
</script>

<template>
  <div v-if="course" class="coorser-course-page">
    <ButtonRedirect text="Назад" @click="$router.push({ name: 'Study' })" />

    <div
      class="coorser-course-page__banner"
      :class="{ 'coorser-course-page__banner_no-photo': !course.media }"
    >
      <img :src="banner" alt="course image" />
    </div>

    <section class="coorser-course-page__main-info main-info">
      <div class="main-info__header">
        <h1>{{ course.name }}</h1>
        <p>{{ course.description }}</p>
      </div>

      <div
        v-if="course.partner && course.partner.user"
        class="main-info__author"
      >
        <img :src="authorPhoto" alt="author" />
        <p>{{ authorName }}</p>
        <p>Автор курса</p>
      </div>

      <CourseProgress v-if="course.stats" :percent="percent" />

      <MainButton
        title="Перейти к курсу"
        color="gold"
        padding="13px 24px"
        @click="toCoorser"
      />
    </section>

    <section
      v-if="
        course.stats &&
        course.stats.completed_by_modules &&
        course.stats.completed_by_modules.length > 0
      "
      class="coorser-course-page__plan plan"
    >
      <h2>План курса</h2>

      <ul class="plan__lessons">
        <LessonItem
          v-for="(lesson, index) in course.stats.completed_by_modules"
          :key="lesson.id"
          :lesson="lesson"
          :index="index"
        />
      </ul>
    </section>

    <footer>
      <MainButton title="Перейти к курсу" color="gold" @click="toCoorser" />
    </footer>
  </div>

  <MainLoader v-else />
</template>

<style scoped lang="scss">
.coorser-course-page {
  padding: 6px 0 0 0;
  background: $background-grey;
  display: flex;
  flex-direction: column;

  &__banner {
    background: $light-primary;
    overflow: hidden;

    img {
      width: 100%;
      height: 108px;
      object-fit: contain;
    }

    &_no-photo img {
      object-fit: cover;
    }
  }
}

.main-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 24px;
  padding: 40px 16px 24px 16px;
  background: $light-primary;
  border-radius: 0 0 20px 20px;
  margin-bottom: 8px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h1 {
      @include title-3;
      color: $dark-primary;
    }

    p {
      @include body-1;
      color: $dark-third;
    }
  }

  &__author {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 36px auto;
    column-gap: 12px;

    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      grid-row: -1/1;
      grid-column: 1/1;
      object-fit: cover;
    }

    p:first-of-type {
      @include body-1-bold;
      color: $dark-primary;
    }

    p:last-of-type {
      @include text-2;
      color: $dark-third;
    }
  }

  .main-button {
    display: none;
  }
}

.plan {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 32px;
  padding: 24px 16px;
  background: $light-primary;
  border-radius: 20px 20px 0 0;

  h2 {
    @include title-3;
    color: $dark-primary;
  }

  &__lessons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.button-redirect {
  position: sticky;
  top: 6px;
  background: $light-primary;
  width: 100%;
  padding: 12px 16px;
  border-top: 1px solid var(--outline-light, rgba(33, 33, 33, 0.1));
  border-bottom: 1px solid var(--outline-light, rgba(33, 33, 33, 0.1));
}

footer {
  width: 100%;
  background: $light-primary;
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  bottom: 0;
}

@media (min-width: 375px) {
  .coorser-course-page {
    &__banner img {
      height: 126px;
    }
  }

  .main-info {
    padding: 40px 24px 24px 24px;
  }

  .plan {
    padding: 24px;
  }
}

@media (min-width: 425px) {
  .coorser-course-page {
    &__banner img {
      height: 144px;
    }
  }

  .main-info {
    padding: 40px 32px 24px 32px;
  }

  .plan {
    padding: 24px 32px;
  }
}

@media (min-width: 576px) {
  .coorser-course-page {
    &__banner img {
      height: 196px;
    }
  }

  .main-info {
    padding: 40px 48px 24px 48px;
  }

  .plan {
    padding: 24px 48px;
  }
}

@media (min-width: 900px) {
  .coorser-course-page {
    padding: 64px 48px;

    &__banner {
      margin-top: 32px;
      border-radius: 20px 20px 0 0;

      img {
        height: 274px;
      }
    }
  }

  .main-info {
    margin-bottom: 32px;
    padding: 32px;

    .main-button {
      display: block;
      width: fit-content;
    }
  }

  .plan {
    border-radius: 20px;
    padding: 32px;
  }

  footer {
    display: none;
  }

  .button-redirect {
    position: relative;
    top: 0;
    background: none;
    width: 100%;
    padding: 12px 16px;
    border: none;
  }
}

@media (min-width: 1024px) {
  .coorser-course-page {
    padding: 48px;
  }

  .main-info {
    padding: 48px;
  }

  .plan {
    padding: 48px;
  }
}

@media (min-width: 1440px) {
  .coorser-course-page {
    max-width: 820px;
  }
}
</style>
