<script>
import IconProgress from "@/components/coorser/IconProgress.vue";

export default {
  name: "LessonItem",
  components: { IconProgress },

  props: {
    lesson: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    percent() {
      if (this.lesson.type !== "module") {
        return this.lesson.is_completed ? 100 : 0;
      }

      return Math.trunc(
        (this.lesson.completed_units * 100) / (this.lesson.total_units || 1)
      );
    },
  },
};
</script>

<template>
  <li class="lesson-item">
    <span class="lesson-item__index">{{ index + 1 }}</span>

    <p>{{ lesson.name }}</p>

    <div class="lesson-item__progress">
      <output>{{ percent }}%</output>
      <IconProgress :id="lesson.id" :progress="percent" />
    </div>
  </li>
</template>

<style scoped lang="scss">
.lesson-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
  padding-bottom: 16px;
  width: 100%;

  &:last-of-type {
    padding-bottom: 0;
  }

  &__index {
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    border-radius: 8px;
    background: $light-third;
    @include text-2-bold;
    color: $dark-primary;
  }

  p {
    @include body-1;
    margin-right: auto;
  }

  &__progress {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 8px;
    @include text-2-bold;
    padding: 7.5px 0;
  }
}
</style>
